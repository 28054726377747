import { apiGet, apiPost } from './index'

export async function listDtu(data) {
	return await apiPost("/api/Dtu/list", data);
}

export async function fullListDtu(data) {
	return await apiPost("/api/Dtu/fulllist", data);
}

export function getDtu(data) {
	let url = "/api/Dtu/read/" + data;
	return apiGet(url, { params: { timestamp: Math.random() } });
}

export function postDtu(data) {
	return apiPost("/api/Dtu/edit", data);
}

export function addDtu(data) {
	return apiPost("/api/Dtu/add", data);
}

export function deleteDtu(data) {
	return apiGet("/api/Dtu/delete/" + data);
}

export function setSimToDtu(data) {
	return apiPost("/api/Dtu/setSimToDtu", data);
}
